<template>
<div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
  <!--begin::Content body-->
  <div class="d-flex flex-column-fluid flex-center mt-lg-0">
    <!--begin::Signin-->
    <div class="login-form login-signin w-100">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">{{ $t('AUTH.SIGN_IN') }}</h3>
        <p class="text-muted font-weight-semi-bold">
          {{ $t('AUTH.LOGIN.DESC') }}
        </p>
      </div>
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <!--begin::Form-->
            <b-form class="form" @submit.stop.prevent="onSubmit">
              <!--                <div role="alert" class="alert alert-info alert-primary cursor-pointer" @click="setPasswordAdmin">-->
              <!--                  <div class="alert-text">-->
              <!--                    Use account <strong>{{admin.username}}</strong> and password-->
              <!--                    <strong>{{admin.password}}</strong> to continue.-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div role="alert" class="alert alert-info alert-light-dark cursor-pointer" @click="setPasswordCompany">-->
              <!--                  <div class="alert-text">-->
              <!--                    Use account COMPANY <strong>{{company.username}}</strong> and password-->
              <!--                    <strong>{{company.password}}</strong> to continue.-->
              <!--                  </div>-->
              <!--                </div>-->

              <!--          <div v-show="errors.length" role="alert" class="alert fade alert-danger">-->
              <!--            <div class="alert-text" v-for="(error, i) in errors" :key="i">-->
              <!--            {{ error }}-->
              <!--            </div>-->
              <!--          </div>-->
              <b-form-group :label="`${$t('FORM.USERNAME')}:`" label-for="input-4">
                <InputForm
                  id="input-4"
                  v-model="$v.form.username.$model"
                  :state="validateState('username')"
                  :placeholder="$t('PLACEHOLDER.USERNAME')"
                  @input="clearServerError('username')"
                  aria-describedby="input-4-feedback"
                />
                <b-form-invalid-feedback id="input-4-feedback">
                  <template v-if="serverErrors.username">{{ serverErrors.username[0] }}</template>
                  <template v-else-if="!$v.form.username.alpha">{{ $t('VALIDATION.INVALID', { name: $t('FORM.USERNAME') }) }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.USERNAME') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group :label="`${$t('FORM.PASSWORD')}:`" label-for="input-3">
                <InputForm
                  id="input-3"
                  type="password"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  :placeholder="$t('PLACEHOLDER.PASSWORD')"
                  @input="clearServerError('password')"
                  aria-describedby="input-3-feedback"
                />
                <b-form-invalid-feedback id="input-3-feedback">
                  <template v-if="serverErrors.password">{{ serverErrors.password[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PASSWORD') }) }}</template>
                </b-form-invalid-feedback>
                <router-link :to="{ name: 'forgotPass' }"
                             class="text-color-dark text-dark-60 text-hover-primary my-3 mr-2"
                             id="kt_login_forgot"
                >{{ $t('AUTH.FORGOT_PASS') }}</router-link>
              </b-form-group>
              <!--begin::Action-->
              <div class="form-group d-flex flex-wrap justify-content-end align-items-end">
                <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="info" size="sm" class="btn-block d-block d-md-none">
                  <b-spinner v-if="loading" small variant="light" />
                  {{ $t('AUTH.SIGN_IN') }}
                </b-button>
                <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="info" size="sm" class="d-none d-md-block">
                  <b-spinner v-if="loading" small variant="light" />
                  {{ $t('AUTH.SIGN_IN') }}
                </b-button>
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Form-->
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!--end::Signin-->
  </div>
  <!--end::Content body-->
</div>
</template>

<script>
import { mapState } from 'vuex'
import { LOGIN, LOGOUT, GET_USER } from '@/core/services/store/auth.module'
import { validationMixin } from 'vuelidate'
import { email, minLength, required } from 'vuelidate/lib/validators'
import serverVuelidate from '../../../mixins/serverVuelidate'
import InputForm from '../../../components/forms-items/input'

export default {
  name: 'Login',
  components: { InputForm },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      // Remove this dummy login info
      form: {
        username: '',
        password: '',
      },
      admin: {
        username: 'admin@admin.com1',
        password: 'Qwerty123',
      },
      company: {
        username: 'test-company-owner+1@gmail.com',
        password: 'Qwerty123',
      },
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  validations: {
    form: {
      username: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    '$route.query': {
      handler(item) {
        if (item.hasOwnProperty('confirmRegister')) {
          if (!item.confirmRegister) {
            this.$bvToast.toast(this.$t('VALIDATION.INVALID_TOKEN'), {
              title: this.$t('TOAST.ERROR'),
              variant: 'danger',
              autoHideDelay: 3000,
              solid: true,
            })
          }
          this.$router.replace({ name: 'login' })
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setPasswordCompany() {
      this.form = { ...this.company }
    },
    setPasswordAdmin() {
      this.form = { ...this.admin }
    },
    resetForm() {
      this.form = {
        username: '',
        password: '',
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      // clear existing errors
      this.$store.dispatch(LOGOUT)

      this.$store.dispatch(LOGIN, this.form)
        .then(() => {
          this.$store.dispatch(GET_USER).then(() => {
            const { path, ...restQuery } = this.$route.query
            this.$router.push({
              path: path || '/',
              query: restQuery, 
            })
            this.loading = false
          }).catch(() => { this.loading = false })
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
          if (err.response.data.code && +err.response.data.code === 400 && !Object.keys(err.response.data.errors).length) {
            this.$bvToast.toast(err.response.data.description, {
              title: this.$t('TOAST.ERROR'),
              variant: 'danger',
              autoHideDelay: 3000,
              solid: true,
            })
          }
        })
    },
  },
}
</script>
